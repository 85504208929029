import React from 'react'

const ApplicationForm = () => {
  return (
    <div className='flex flex-center'>
      comming soon
    </div>
  )
}

export default ApplicationForm