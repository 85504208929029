import React from 'react'
import FeaturedStudio from './FeaturedStudio'

const FeaturedSection = () => {
  return (
    <div className='FeaturedSection'>
        {/* <FeaturedStudio />
        <FeaturedStudio />
        <FeaturedStudio />
        <FeaturedStudio /> */}
    </div>
  )
}

export default FeaturedSection