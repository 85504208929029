import React from 'react'

const BlogSectionSponsor = () => {
  return (
    <div className='BlogSectionSponsor flex flex-center'>
        Sponsor name
    </div>
  )
}

export default BlogSectionSponsor