import React from 'react'

const AdSpace = () => {
  return (
    <div className='AdSpaceYo'>
      

      <span className='font-small'>Grow your business with us</span>
      
    </div>
  )
}

export default AdSpace